import { Typography, TypographyType } from '@hp/atomic';
import { config } from '@hp/config';
import { Address } from '@hp/core/shared';
import { formatPhone } from '@hp/form/src/atomic/phoneInput/utils';
import { fields } from '@hp/locale';
import { formatPostalCode } from '@hp/utils';
import { Trans } from '@lingui/react';
import React, { FC } from 'react';

import { CountryName } from '../countryName';

export type AddressViewProps = {
  address: Address;
};

export const AddressView: FC<AddressViewProps> = ({
  address: {
    city,
    country,
    name,
    name2,
    street,
    zip,
    phone,
    phonePrefix,
    dic,
    ico,
    forInvoicing,
  },
  children,
}) => {
  return (
    <>
      <Typography type={TypographyType.BodySmall}>
        {[name, name2]
          .filter((x) => !!x)
          .map((value, i) => (
            <div key={i}>{value}</div>
          ))}
      </Typography>
      <Typography type={TypographyType.BodyMicroLight}>
        {[street, city]
          .filter((x) => !!x)
          .map((value, i) => (
            <div key={i}>{value}</div>
          ))}

        {!!zip && <div>{formatPostalCode(zip, country)}</div>}

        {country.toLowerCase() !== config.app.defaultCountry.toLowerCase() && (
          <div>
            <CountryName country={country} />
          </div>
        )}

        {ico && (
          <div>
            {fields.ico}: {ico}
          </div>
        )}
        {dic && (
          <div>
            {fields.dic}: {dic}
          </div>
        )}
        {!forInvoicing && (
          <>
            <div>
              <Trans id="common.phone.abrevation">Tel</Trans>:{' '}
              {formatPhone(`+${phonePrefix}${phone}`)}
            </div>
          </>
        )}
      </Typography>
      {children}
    </>
  );
};
