import { Typography, TypographyProps, TypographyType } from '@hp/atomic';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { colors } from '@hp/theme';
import React from 'react';

import { getMoneySymbol } from './getMoneySymbol';
import { Currency } from './types';

type MoneySymbolIcon = { currency: Currency } & Omit<TypographyProps, 'type'>;

export const MoneySymbolIcon: React.FC<MoneySymbolIcon> = ({
  currency,
  color = colors.gray_mid,
  ...props
}) => {
  const { language } = useLanguage();
  return (
    <Typography type={TypographyType.BodyMicroLight} color={color} {...props}>
      {getMoneySymbol(currency, language).symbol}
    </Typography>
  );
};
