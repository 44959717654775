import { Note, NoteProps } from '@hp/atomic';
import { CenteredRow, WideWrapperMobile } from '@hp/layout';
import React from 'react';

export const WideNoteOnMobile: React.FC<NoteProps> = ({
  children,
  ...props
}) => (
  <WideWrapperMobile fullWidth>
    <CenteredRow marginSize="m">
      <Note {...props}>{children}</Note>
    </CenteredRow>
  </WideWrapperMobile>
);
