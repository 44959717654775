import { Typography } from '@hp/atomic';
import { spacing } from '@hp/theme';
import styled from 'styled-components';

import { BadgeProps } from './types';

export const BadgeWrapper = styled.span<BadgeProps>`
  display: inline-block;
  width: fit-content;
  white-space: nowrap;
  height: 24px;
  background-color: ${({ color }) => color};
  padding: ${spacing.zero} ${spacing.s};
  border-radius: 5px;
  margin: ${({ margin }) => margin ?? spacing.xs};
  transition: background-color 0.5s;
`;

export const BadgeText = styled(Typography)`
  position: relative;
  top: -1px;
`;
