import { config } from '@hp/config';
import { AppContextType } from 'next/dist/next-server/lib/utils';
import React, { Component } from 'react';

import { UserContextProvider } from './user';

export const withContext = (ComposedComponent) => {
  return class WithGraphData extends Component {
    static async getInitialProps(appContext: AppContextType) {
      // Evaluate the composed component's getInitialProps()
      let composedInitialProps = {};
      if (ComposedComponent.getInitialProps) {
        composedInitialProps = await ComposedComponent.getInitialProps({
          ...appContext,
        });
      }

      return {
        ...composedInitialProps,
      };
    }

    constructor(props) {
      super(props);
    }

    render() {
      return (
        <UserContextProvider config={config.app.firebaseConfig}>
          <ComposedComponent {...this.props} />
        </UserContextProvider>
      );
    }
  };
};
