import { ProgressBar } from '@hp/components';
import { ProgressBarProps } from '@hp/components/src/progressBar/types';
import { useConfig } from '@hp/config';
import { Country, DynamicConfigRoot, getSpecificCfg } from '@hp/core/shared';
import {
  HowToSendFormFields,
  ParcelFormProps,
  ParcelPartDescription,
} from '@hp/order';
import { RoutesType } from '@hp/seo';
import { hasFeature, useObjectLocalStorage } from '@hp/utils';
import React, { useContext, useEffect, useState } from 'react';

import { getProgressBarSteps } from './getProgressBarSteps';
import { StepsType } from './types';

const ProgressBarContext = React.createContext<ProgressBarContextProps>(null);

type ProgressBarContextProps = {
  progressBar: ProgressBarProps;
  setProgressBar: React.Dispatch<React.SetStateAction<ProgressBarProps>>;
};

type Props = {
  route: keyof RoutesType;
  bypass?: boolean;
};

const getStepsType = (
  items: ParcelPartDescription[],
  countryTo: Country,
  config: DynamicConfigRoot,
) => {
  if (!items || !countryTo) return null;
  const specList = getSpecificCfg(config.parcelSpecifications, countryTo);

  const multiOrXL =
    items?.length > 1 || items?.some((x) => !!specList[x.size].ranges?.length);

  return multiOrXL ? StepsType.PARCELSIZE : StepsType.DEFAULT;
};

export const ProgressBarProvider: React.FC<Props> = ({
  children,
  route,
  bypass,
}) => {
  const { config } = useConfig();

  const [parcel] = useObjectLocalStorage<ParcelFormProps>('parcel', null, {
    lazy: true,
  });
  const [howToSend] = useObjectLocalStorage<HowToSendFormFields>(
    'howToSend',
    null,
    { lazy: true },
  );

  const [progressBar, setProgressBar] = useState<ProgressBarProps>({
    activeStep: bypass ? 0 : 1,
    steps: bypass ? [] : getProgressBarSteps(),
  });

  useEffect(() => {
    if (bypass) return;
    const stepsType = hasFeature('v3')
      ? getStepsType(parcel?.items, howToSend?.sendToCountry, config)
      : StepsType.DEFAULT;
    const steps = getProgressBarSteps(stepsType);
    const activeStep = steps.findIndex((x) => x.route === route) + 1;
    setProgressBar({ ...progressBar, steps, activeStep });
  }, [route, parcel, howToSend, bypass]);

  return (
    <ProgressBarContext.Provider value={{ progressBar, setProgressBar }}>
      {children}
    </ProgressBarContext.Provider>
  );
};

export const useProgressBar = () => {
  const ctx = useContext(ProgressBarContext);
  return ctx;
};

/** renders progress bar based on props defined in ProgressBarProvider. There is still possibility to override contextual props. */
export const ManagedProgressBar: React.FC<Partial<ProgressBarProps>> = ({
  children,
  ...overridingProps
}) => {
  const { progressBar } = useProgressBar();
  return progressBar.activeStep /*0 or null*/ ? (
    <ProgressBar {...progressBar} {...overridingProps}>
      {children}
    </ProgressBar>
  ) : null;
};
