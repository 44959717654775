import { StepType } from '@hp/components/src/progressBar/types';
import { RouteNameType } from '@hp/seo';
import { hasFeature } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React from 'react';

import { StepsType } from './types';

export const getProgressBarSteps = (type = StepsType.DEFAULT) => {
  const all: Array<StepType> = [
    {
      text: hasFeature('v3') ? (
        <Trans id="common.progressBarStep.delivery">Doručení</Trans>
      ) : (
        <Trans id="common.progressBarStep.parcel">Rozměry</Trans>
      ),
      route: (hasFeature('v3')
        ? 'orderDelivery'
        : 'orderParcel') as RouteNameType,
    },
    {
      text: <Trans id="common.progressBarStep.recipient">Komu</Trans>,
      route: 'orderRecipient' as RouteNameType,
    },
    {
      text: <Trans id="common.progressBarStep.sender">Od koho</Trans>,
      route: 'orderSender' as RouteNameType,
    },
    {
      text: (
        <Trans id="common.progressBarStep.additionalServices">Služby</Trans>
      ),
      route: 'orderAdditionalServices' as RouteNameType,
    },
    {
      text: <Trans id="common.progressBarStep.sumary">Shrnutí</Trans>,
      route: 'orderSummary' as RouteNameType,
    },
  ];
  switch (type) {
    case StepsType.PARCELSIZE:
      const withParcel = [
        {
          text: <Trans id="common.progressBarStep.weight">Hmotnost</Trans>,
          route: 'orderParcel' as RouteNameType,
        },
        {
          text: <Trans id="common.progressBarStep.pickup">Vyzvednutí</Trans>,
          route: 'orderDelivery' as RouteNameType,
        },
        ...all.slice(1),
      ];
      return withParcel;
    case StepsType.DEFAULT:
    default:
      return all;
  }
};
