import { Strong, UpperCase } from '@hp/atomic';
import { ParcelSize, ParcelSpecsList, Range } from '@hp/core/shared';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { formatNumber } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React from 'react';

type ParcelDescriptionProps = {
  size: ParcelSize;
  weight?: number | null;
  index?: number;
  strong?: boolean;
  specsList: ParcelSpecsList;
};

const getRangeByWeight = (ranges: Range[], weight: number) => {
  return ranges.find((r) => r[0] <= weight && weight <= r[1]);
};
const getRangeMaxWeight = (range: Range) => {
  return range[1];
};

const getMaxWeight = (ranges: Range[], weight: number) =>
  getRangeMaxWeight(getRangeByWeight(ranges, weight));

export const ParcelDescription: React.FC<ParcelDescriptionProps> = ({
  size,
  weight,
  index,
  strong = false,
  specsList,
}) => {
  const { ranges } = specsList[size];
  const { language } = useLanguage();

  const hasRanges = ranges?.length > 0;

  const maxWeight = hasRanges
    ? formatNumber(getMaxWeight(ranges, weight), language)
    : null;

  const content = (
    <>
      {index === null || index === undefined ? (
        <UpperCase mode="capitalize">
          <Trans id="common.parcel">balík</Trans>
        </UpperCase>
      ) : (
        <>
          {/* TODO: capitalizeFirstLetter not working, why? */}
          {/* <UpperCase mode="capitalizeFirstLetter"> */}
          <Trans id="common.parcel.mps">Balík č. {index + 1}</Trans>
          {/* </UpperCase> */}
          &nbsp;-&nbsp;
        </>
      )}{' '}
      {size}{' '}
      {maxWeight ? (
        <Trans id="order.parcel.weight.till">do&nbsp;{maxWeight}&nbsp;kg</Trans>
      ) : null}
    </>
  );

  if (strong) return <Strong>{content}</Strong>;

  return <span>{content}</span>;
};
