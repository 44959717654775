import { breakpoints, colors, elements, spacing, typography } from '@hp/theme';
import styled, { css } from 'styled-components';

import { BannerProps } from '../types';

export const Container = styled.div<BannerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: ${colors.white};
  height: 172px;
  max-width: 288px;
  background-color: ${colors.green_banner};
  border-radius: 0px 20px 20px 20px;

  ${({ placement }) =>
    placement === 'account' &&
    css`
      background-image: url('/images/banners/leaves_top_left_account.svg'),
        url('/images/banners/plug_top_right_account.svg'),
        url('/images/banners/plug_bottom_left_account.svg'),
        url('/images/banners/leaves_bottom_right_account.svg');
      background-position: top left, top right 15px, bottom left 10px,
        bottom right;
    `};

  ${({ placement }) =>
    placement === 'order' &&
    css`
      background-image: url('/images/banners/leaves_top_left.svg'),
        url('/images/banners/leaves_top_right.svg'),
        url('/images/banners/leaves_bottom_right.svg');
      background-position: top left, top right, bottom right;
    `};

  background-repeat: no-repeat;
  padding: 25px 31px;
  margin: ${spacing.m} auto ${spacing.l};

  @media (min-width: ${breakpoints.minDesktop}) {
    align-items: flex-start;
    height: ${({ placement }) => (placement === 'account' ? '136px' : '172px')};
    width: ${({ placement }) => (placement === 'account' ? '780px' : '504px')};
    max-width: ${elements.innerElementMaxWidth};

    ${({ placement }) =>
      placement === 'account' &&
      css`
        background-image: url('/images/banners/plug_top_left_account_desktop.svg'),
          url('/images/banners/leaves_top_right_account_desktop.svg'),
          url('/images/banners/leaves_bottom_left_account_desktop.svg'),
          url('/images/banners/plug_bottom_right_account_desktop.svg');
        background-position: top 20px left, top right 30px, bottom left,
          bottom 25px right;
      `};

    ${({ placement }) =>
      placement === 'order' &&
      css`
        background-image: url('/images/banners/leaves_top_left_desktop.svg'),
          url('/images/banners/leaves_top_right_desktop.svg'),
          url('/images/banners/leaves_bottom_left_account_desktop.svg'),
          url('/images/banners/leaves_bottom_right_desktop.svg');
        background-position: top left, top right, bottom left, bottom right;
      `};

    padding: 26px 130px 26px 120px;
    padding: ${({ placement }) =>
      placement === 'account'
        ? '26px 130px 26px 120px'
        : '20px 110px 20px 100px'};
  }
`;

export const Heading = styled.h3<BannerProps>`
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: ${typography.fontWeight.normal};
  margin: 0;

  @media (min-width: ${breakpoints.minDesktop}) {
    font-size: 20px;
    line-height: 20px;
    text-align: left;
  }
`;

export const Wrapper = styled.div<BannerProps>`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${breakpoints.minDesktop}) {
    flex-direction: ${({ placement }) =>
      placement === 'account' ? 'row' : 'column'};
    align-items: ${({ placement }) =>
      placement === 'account' ? 'center' : 'flex-start'};
    justify-content: flex-start;
  }
`;

export const Paragraph = styled.p<BannerProps>`
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  font-weight: ${typography.fontWeight.light};
  margin: 0;

  @media (min-width: ${breakpoints.minDesktop}) {
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    max-width: 263px;
    margin-right: ${({ placement }) => placement === 'account' && '58px'};
    margin-bottom: ${({ placement }) => placement === 'order' && '8px'};
  }
`;
