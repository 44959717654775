import { Language } from '@hp/config';

import { Currency } from './types';

export const getMoneySymbol = (currency: Currency, language: Language) => {
  switch (currency) {
    case 'EUR':
      return { symbol: 'EUR', symbolAfter: true };
    default:
      return {
        symbol: language === Language.CS ? 'Kč' : 'CZK',
        symbolAfter: true,
      };
  }
};
