import { Button, ButtonType, ChevronIcon, Nowrap } from '@hp/atomic';
import { useConfig } from '@hp/config';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import { BannerProps } from '../types';
import { Container, Heading, Paragraph, Wrapper } from './styled';

export const EcoBanner: FC<BannerProps> = ({ placement, size = 'S' }) => {
  const { language } = useLanguage();
  const { config } = useConfig();

  const { responsibility } =
    config.externalLinks[language] ?? config.externalLinks.default;

  const heading =
    placement === 'account' ? (
      <Trans id="banner.eco.heading.account">
        Ve městech <Nowrap onlyMobile>doručujeme s citem</Nowrap>
      </Trans>
    ) : (
      <Trans id="banner.eco.heading.order">
        I tuto zásilku doručíme <Nowrap>ohleduplně k přírodě</Nowrap>
      </Trans>
    );

  const paragraph =
    placement === 'account' ? (
      <Trans id="banner.eco.paragraph.account">
        Kompenzujeme uhlíkovou stopu <Nowrap>a kde</Nowrap> to jde,{' '}
        <Nowrap>s balíčky</Nowrap> chodíme pěšky nebo je vozíme na
        elektrokolech.
      </Trans>
    ) : (
      <Trans id="banner.eco.paragraph.order">
        Posilujeme ekologickou přepravu <Nowrap>a kompenzujeme</Nowrap>{' '}
        uhlíkovou stopu.
      </Trans>
    );

  const button =
    placement === 'account' ? (
      <Trans id="banner.eco.button.account">Kde je náš uhlík?</Trans>
    ) : (
      <Trans id="banner.eco.button.order">Jak to děláme?</Trans>
    );

  return (
    <Container placement={placement} size={size}>
      <Heading placement={placement} size={size}>
        {heading}
      </Heading>

      <Wrapper placement={placement}>
        <Paragraph placement={placement} size={size}>
          {paragraph}
        </Paragraph>

        <Button
          type="button"
          buttonType={ButtonType.BANNER_SECONDARY}
          icon={<ChevronIcon color={colors.green_banner} zoom={0.8} />}
          className="gaButton gaButtonAction_ecoBanner"
          data-test="thankYou.button.ecoBanner"
          onClick={(e) => {
            e.preventDefault();
            window.open(responsibility);
          }}
        >
          {button}
        </Button>
      </Wrapper>
    </Container>
  );
};
