import { ParcelSize, ParcelSpecsList } from '@hp/core/shared';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { colors } from '@hp/theme';
import { formatNumber } from '@hp/utils';
import { Trans } from '@lingui/macro';
import React from 'react';
import styled from 'styled-components';

type ParcelWeightDescriptionProps =
  | {
      size: ParcelSize;
      specList: ParcelSpecsList;
      value?: undefined;
      highlightWeight?: boolean;
      onlyWeight?: boolean;
    }
  | {
      size?: undefined;
      specList?: undefined;
      value: number;
      highlightWeight?: boolean;
      onlyWeight?: boolean;
    };

const Highlight = styled.span`
  color: ${colors.red_main};
`;

export const ParcelWeightDescription: React.FC<ParcelWeightDescriptionProps> = ({
  value,
  specList,
  size,
  highlightWeight,
  onlyWeight,
}) => {
  const { language } = useLanguage();
  const maxWeight = formatNumber(value ?? specList[size].maxWeight, language);

  if (highlightWeight)
    return onlyWeight ? (
      <Trans id="order.parcel.weight.highlighted">
        <Highlight>{maxWeight}</Highlight>&nbsp;kg
      </Trans>
    ) : (
      <Trans id="order.parcel.weight.till.highlighted">
        do&nbsp;<Highlight>{maxWeight}</Highlight>&nbsp;kg
      </Trans>
    );

  return onlyWeight ? (
    <Trans id="order.parcel.weight">{maxWeight}&nbsp;kg</Trans>
  ) : (
    <Trans id="order.parcel.weight.till">do&nbsp;{maxWeight}&nbsp;kg</Trans>
  );
};
