import { GoogleMap, Link, Strong } from '@hp/atomic';
import { Trans } from '@lingui/react';
import React, { FC, useState } from 'react';

import { Collapsible, Row } from './styled';

type ParcelShopCoordinates = {
  latitude: number;
  longitude: number;
};

type MapInfoProps = {
  parcelShop: ParcelShopCoordinates;
  alwaysExpanded?: boolean;
};

export const MapInfo: FC<MapInfoProps> = ({
  parcelShop,
  alwaysExpanded = false,
}) => {
  const [initialLoad, setInitialLoad] = useState(false);
  const [showMap, setShowMap] = useState(false);

  if (!parcelShop) {
    return null;
  }

  const { latitude, longitude } = parcelShop;

  const onMapLink = () => {
    if (!initialLoad) {
      setInitialLoad(true);
      setTimeout(() => setShowMap(!showMap), 10);
    } else {
      setShowMap(!showMap);
    }
  };

  return (
    <>
      {!alwaysExpanded && (
        <Link onClick={onMapLink} textAlign="right">
          <Strong>
            {showMap ? (
              <Trans id="components.mapInfo.hideMap">Skrýt mapu</Trans>
            ) : (
              <Trans id="components.mapInfo.showMap">Ukázat mapu</Trans>
            )}
          </Strong>
        </Link>
      )}

      {(initialLoad || alwaysExpanded) && (
        <Collapsible collapsed={showMap || alwaysExpanded}>
          <Row>
            <GoogleMap position={{ lat: latitude, lng: longitude }} />
          </Row>
        </Collapsible>
      )}
    </>
  );
};
