import { config } from '@hp/config';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import countryName from 'country-name';
import React from 'react';

import {
  CenteredFlexRow,
  LabelStyled,
  ReactCountryFlagStyled,
  Wrapper,
} from './styled';
import { CountryNameLikeFieldProps, CountryNameProps } from './types';

export const CountryName: React.FC<CountryNameProps> = ({
  country,
  reversed,
}) => {
  const { language } = useLanguage();

  return (
    <CenteredFlexRow>
      {!reversed && countryName(country, language)}
      <ReactCountryFlagStyled countryCode={country} svg reversed={reversed} />
      {reversed && countryName(country, language)}
    </CenteredFlexRow>
  );
};

export const CountryNameLikeField: React.FC<CountryNameLikeFieldProps> = ({
  showOnlyNonDefault,
  label,
  ...props
}) => {
  return showOnlyNonDefault &&
    props.country.toLowerCase() ===
      config.app.defaultCountry.toLowerCase() ? null : (
    <Wrapper>
      <LabelStyled>{label}</LabelStyled>
      <CountryName reversed {...props} />
    </Wrapper>
  );
};
