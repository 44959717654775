import { NextLink } from '@hp/atomic';
import { HorizontalLayout } from '@hp/layout';
import { breakpoints, colors } from '@hp/theme';
import styled from 'styled-components';

import { ProgressStepType } from './types';

export const Container = styled.div`
  background-color: ${colors.white};
  padding: 0;

  @media (min-width: ${breakpoints.minDesktop}) {
    padding: 4px 0 0;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: ${breakpoints.fullWidth};
  margin: auto;
  padding: 0;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 30px;
  width: stretch;
  margin-right: 8px;
  border-radius: 10px;

  @media (min-width: ${breakpoints.minDesktop}) {
    margin-right: 24px;
  }

  :last-child {
    margin-right: 0;
  }
`;

export const StepLink = styled(NextLink)`
  display: flex;
  flex-direction: column;
  min-width: 30px;
  width: stretch;
  margin-right: 8px;
  border-radius: 10px;

  @media (min-width: ${breakpoints.minDesktop}) {
    margin-right: 24px;
  }

  :last-child {
    margin-right: 0;
  }
`;

export const StepBar = styled.div<Pick<ProgressStepType, 'complete'>>`
  height: 8px;
  min-width: 30px;
  width: stretch;
  color: ${({ complete }) => (complete ? colors.red_main : colors.white)};
  background: ${({ complete }) =>
    complete ? colors.red_main : colors.gray_light};
  border-radius: 10px;

  :last-child {
    margin-right: 0;
  }
`;

export const StepText = styled.div`
  display: none;

  @media (min-width: ${breakpoints.minDesktop}) {
    display: block;
  }
`;

export const HorizontaLayoutStyled = styled(HorizontalLayout)`
  @media (max-width: ${breakpoints.maxMobile}) {
    align-self: flex-end;
  }
`;
