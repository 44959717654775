import { Nowrap } from '@hp/atomic';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { MediaQueryJS } from '@hp/layout';
import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import { BannerProps } from '../types';
import { Heading, Paragraph, Wrapper } from './styled';

export const HandBanner: FC<BannerProps> = ({ placement, size = 'S' }) => {
  const { language } = useLanguage();

  return (
    <Wrapper placement={placement} size={size} lang={language}>
      <Heading placement={placement} size={size}>
        <MediaQueryJS>
          {(isMobileScreen: boolean) => (
            <>
              {isMobileScreen ? (
                //different nowrap in EN version on mobile
                <Trans id="banner.hand.heading.enMobileVersion">
                  Zásilky <Nowrap>doručujeme</Nowrap>{' '}
                  <Nowrap>z ruky do ruky</Nowrap>
                </Trans>
              ) : (
                <Trans id="banner.hand.heading">
                  Zásilky <Nowrap>doručujeme</Nowrap>{' '}
                  <Nowrap>z ruky do ruky</Nowrap>
                </Trans>
              )}
            </>
          )}
        </MediaQueryJS>
      </Heading>

      <Paragraph placement={placement} size={size}>
        <Trans id="banner.hand.paragraph">
          Navíc tak, aby se příjemci hodilo místo i termín.
        </Trans>
      </Paragraph>
    </Wrapper>
  );
};
