import 'firebase/auth';

import { Language } from '@hp/config';
import { RouteNameType, useRouter } from '@hp/seo';
import type { InitializeAppArgs } from '@react-firebase/auth/dist/types';
import firebase from 'firebase/app';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { User } from './types';

const UserContext = React.createContext<UserContextProps>(null);

const login = async (username: string, password: string) => {
  const cred = await firebase
    .auth()
    .signInWithEmailAndPassword(username, password);

  return cred?.user;
};
const logout = () => {
  return firebase.auth().signOut();
};
const createUser = async (username: string, password: string) => {
  const cred = await firebase
    .auth()
    .createUserWithEmailAndPassword(username, password);
  return cred?.user;
};

const deleteUser = async (password: string) => {
  const email = firebase.auth().currentUser.email;
  await firebase.auth().signInWithEmailAndPassword(email, password);
  await firebase.auth().currentUser.delete();
};

const changePassword = async (currentPassword: string, newPassord: string) => {
  const { email } = firebase.auth().currentUser;
  const { user } = await firebase
    .auth()
    .signInWithEmailAndPassword(email, currentPassword);

  await user.updatePassword(newPassord);
};

const checkPassword = async (currentPassword: string) => {
  const { email } = firebase.auth().currentUser;
  await firebase.auth().signInWithEmailAndPassword(email, currentPassword);
};

const verifyPasswordResetCode = (code: string) => {
  return firebase.auth().verifyPasswordResetCode(code);
};

const setPassword = (code: string, newPassword: string) => {
  return firebase.auth().confirmPasswordReset(code, newPassword);
};

const userExists = async (email: string) => {
  const schema = Yup.string().email();
  if (!schema.isValidSync(email)) return false;
  try {
    const signInMethods = await firebase
      .auth()
      .fetchSignInMethodsForEmail(email);
    return !!signInMethods.length;
  } catch (e) {
    if (e?.code !== 'auth/invalid-email') throw e;
    return false;
  }
};

const errored = {};

export type UserContextProps = {
  user?: User;
  loading: boolean;
  logout?: () => Promise<void>;
  login?: (username: string, password: string) => Promise<firebase.User>;
  createUser?: (username: string, password: string) => Promise<firebase.User>;
  userExists?: (username: string) => Promise<boolean>;
  deleteUser?: (password: string) => Promise<void>;
  changePassword?: (password: string, newPassord: string) => Promise<void>;
  checkPassword?: (password: string) => Promise<void>;
  setPassword?: (code: string, newPassord: string) => Promise<void>;
  verifyPasswordResetCode?: (code: string) => Promise<string>;
  sendPasswordResetEmail?: (
    username: string,
    route: RouteNameType,
  ) => Promise<void>;
  registerHook: (
    eventName: string,
    handler: (params: any) => void,
  ) => () => void;
};

export type UserProviderProps = {
  config: InitializeAppArgs;
};

const initializeFirebase = (config: InitializeAppArgs) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }
};

const defaultState: Pick<UserContextProps, 'user' | 'loading'> = {
  loading: true,
};

const createFullUser = (user: firebase.User): User => {
  //todo: take a proper data
  return { user, data: { language: Language.CS } };
};

export const UserContextProvider: React.FC<UserProviderProps> = ({
  children,
  config,
}) => {
  const [{ loading, user }, setUser] = useState(defaultState);
  const [handlers, setHandlers] = useState<
    Record<string, ((params: any) => void)[]>
  >({});

  const withHooks = <T extends (...args: any) => any>(
    eventName: string,
    action: T,
  ) => {
    return (...args: Parameters<T>) => {
      const res = action(...args) as ReturnType<T>;
      Promise.resolve(res)
        .catch(() => errored)
        .then((output) => {
          if (output === errored) return;
          const callbacks = handlers[eventName] ?? [];
          callbacks.forEach((cb) => cb(output));
        });
      return res;
    };
  };

  const router = useRouter();
  const sendPasswordResetEmail = async (
    username: string,
    route: RouteNameType,
  ) =>
    firebase.auth().sendPasswordResetEmail(username, {
      url: router.getGlobalUrl(route),
    });

  useEffect(() => {
    initializeFirebase(config);
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setUser({ loading: false, user: user ? createFullUser(user) : null });
      //@ts-ignore - for dev purposes
      window.dpdLogout = user
        ? () => logout().then(() => console.log('User has logout.'))
        : () => console.log('No one was here..');
    });
    return () => unsubscribe();
  }, [config]);

  const registerHookF = (eventName: string, handler: (params: any) => void) => {
    setHandlers((prev) => {
      const items = prev[eventName] ?? [];
      return items.includes(handler)
        ? prev
        : { ...prev, [eventName]: [...items, handler] };
    });
    const unregister = () => {
      setHandlers((prev) => {
        const filtered = prev[eventName].filter((x) => x !== handler);
        if (filtered.length === prev[eventName].length) return prev;
        return {
          ...prev,
          [eventName]: filtered,
        };
      });
    };
    return unregister;
  };
  const registerHook = useCallback(registerHookF, []);

  const hasUser = !loading && !!user;

  return (
    <UserContext.Provider
      value={{
        user,
        logout: hasUser ? withHooks('logout', logout) : undefined,
        login: hasUser ? undefined : withHooks('login', login),
        createUser: hasUser ? undefined : withHooks('createUser', createUser),
        sendPasswordResetEmail: hasUser ? undefined : sendPasswordResetEmail,
        userExists: hasUser ? undefined : userExists,
        deleteUser: hasUser ? withHooks('deleteUser', deleteUser) : undefined,
        changePassword: hasUser ? changePassword : undefined,
        checkPassword: hasUser ? checkPassword : undefined,
        setPassword: hasUser ? undefined : setPassword,
        verifyPasswordResetCode: hasUser ? undefined : verifyPasswordResetCode,
        loading,
        registerHook,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  //during SSR rendering, when componenet is probed in getDataFromTree() useContext may return null;
  return useContext(UserContext) ?? (defaultState as UserContextProps);
};
