import { EyeIcon, HideEyeIcon } from '@hp/atomic';
import { useI18n } from '@hp/locale';
import { colors, typography } from '@hp/theme';
import { MessageDescriptor } from '@lingui/core';
import React, { forwardRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { Checkmark, CheckmarkWrapper, Exclamation } from './styled';

export type InputProps = {
  placeholderDescriptor?: MessageDescriptor;
  error?: boolean;
  touched?: boolean;
  height?: string;
  extraTopPadding?: number;
  withLabel?: boolean;
  noCheckMark?: boolean;
  customIcon?: React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
} & Omit<
  React.HTMLProps<HTMLInputElement>,
  'as' | 'ref' | 'label' | 'onChange'
> /* due to ts/react conflict */;

const Wrapper = styled.div<Pick<InputProps, 'extraTopPadding'>>`
  position: relative;
  top: ${({ extraTopPadding }) => `${-6 + (extraTopPadding || 0)}px`};
`;

export const InputsInfoIcons = styled.div<
  Pick<InputProps, 'withLabel' | 'extraTopPadding'> & { forSelect?: boolean }
>`
  position: absolute;
  right: ${({ forSelect }) => (forSelect ? '0' : '-28px')};
  display: flex;
  flex-direction: row;
  top: ${({ withLabel, extraTopPadding }) =>
    withLabel
      ? `${-22 + (extraTopPadding || 0)}px`
      : `${-18 + (extraTopPadding || 0)}px`};
`;

export const CustomIcons = styled.div<Pick<InputProps, 'withLabel'>>`
  position: absolute;
  right: -28px;
  top: ${({ withLabel }) => (withLabel ? '-5px' : '3px')};
`;

const IconWrapper = styled.span`
  user-select: none;
  cursor: pointer;
`;

const Eye = styled(EyeIcon)`
  margin-top: 13px;
  margin-right: 4px;
`;

const HideEye = styled(HideEyeIcon)`
  margin-top: 13px;
  margin-right: 4px;
`;

const InputStyled = styled.input<InputProps & { originType: string }>`
  color: ${({ disabled }) => (disabled ? colors.gray_mid : colors.black)};
  width: 100%;
  border: none;
  font-size: ${typography.fontSize.input.text};
  line-height: ${typography.lineHeight.body};
  /* line-height: ${({ height }) =>
    !height ? typography.lineHeight.body : `calc(${height} + 10px)`}; */
  outline: none;
  transition: border-bottom-color linear 0.5s;
  border-radius: 0;
  background-color: transparent;
  input[type='search'] {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${colors.black};
    opacity: 0.5;
  }
  -webkit-text-fill-color: ${({ disabled }) =>
    disabled ? colors.gray_mid : colors.black};

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    box-shadow: 0 0 0 30px ${colors.input} inset !important;
  }

  ${({ originType }) =>
    originType === 'password' &&
    css`
      padding-right: 40px;

      ::-ms-reveal {
        display: none;
      }

      ::-webkit-credentials-auto-fill-button {
        position: relative;
        right: 35px;
        background: ${colors.black};
      }
    `};
`;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      error,
      touched,
      placeholderDescriptor,
      value,
      withLabel,
      noCheckMark,
      customIcon,
      extraTopPadding,
      ...props
    },
    ref,
  ) => {
    const i18n = useI18n();
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = (event, val: boolean) => {
      if (event.cancelable) {
        event.preventDefault();
      }
      event.cancelBubble = true;
      event.stopPropagation?.();
      event.stopImmediatePropagation?.();
      if (val === showPassword) {
        return;
      }
      setShowPassword(val);
    };

    return (
      <Wrapper extraTopPadding={extraTopPadding}>
        <InputStyled
          originType={type}
          type={showPassword ? 'text' : type}
          placeholder={
            placeholderDescriptor ? i18n._(placeholderDescriptor.id) : null
          }
          ref={ref}
          error={error}
          touched={touched}
          value={value}
          withLabel={withLabel}
          {...props}
        />
        <InputsInfoIcons
          withLabel={withLabel}
          extraTopPadding={extraTopPadding}
        >
          {type === 'password' &&
            (showPassword ? (
              <IconWrapper
                onMouseDown={(event) => handleShowPassword(event, false)}
                onTouchStart={(event) => handleShowPassword(event, false)}
                onPointerDown={(event) => handleShowPassword(event, false)}
              >
                <HideEye />
              </IconWrapper>
            ) : (
              <IconWrapper
                onMouseDown={(event) => handleShowPassword(event, true)}
                onTouchStart={(event) => handleShowPassword(event, true)}
                onPointerDown={(event) => handleShowPassword(event, true)}
              >
                <Eye />
              </IconWrapper>
            ))}
          {error && <Exclamation>!</Exclamation>}
          {touched && !error && !!value && !noCheckMark && (
            <CheckmarkWrapper>
              <Checkmark />
            </CheckmarkWrapper>
          )}
        </InputsInfoIcons>
        <CustomIcons withLabel={withLabel}>{customIcon}</CustomIcons>
      </Wrapper>
    );
  },
);
