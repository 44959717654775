import { CourierColorsIcon, LockerIcon, ParcelShopIcon } from '@hp/atomic';
import { PickupType, PlaceType } from '@hp/core/shared';
import React from 'react';

export type PickupDeliveryIconProps = {
  type: PickupType | PlaceType;
  customIcons?: Partial<Record<PickupType, JSX.Element>>;
  className?: string;
};

export const PickupDeliveryIcon = ({
  type,
  customIcons,
  className,
}: PickupDeliveryIconProps) => {
  switch (type) {
    case PickupType.PARCEL_SHOP:
    case PlaceType.PARCEL_SHOP:
      return (
        customIcons?.[PickupType.PARCEL_SHOP] ?? (
          <ParcelShopIcon className={className} />
        )
      );
    case PickupType.LOCKER:
    case PlaceType.LOCKER:
      return (
        customIcons?.[PickupType.LOCKER] ?? <LockerIcon className={className} />
      );
    default:
      return (
        customIcons?.[PickupType.COURIER] ?? (
          <CourierColorsIcon className={className} />
        )
      );
  }
};
