import { ConditionalWrapper } from '@hp/atomic';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { formatNumber } from '@hp/utils';
import React from 'react';

import { getMoneySymbol } from './getMoneySymbol';
import { Currency } from './types';

export { MoneySymbolIcon } from './MoneySymbolIcon';

type Props = {
  currency: Currency;
  children: number;
  block?: boolean;
};

export const Money = ({ currency, children, block }: Props) => {
  const { language } = useLanguage();
  const { symbol, symbolAfter } = getMoneySymbol(currency, language);

  return (
    <ConditionalWrapper
      condition={block}
      wrapper={(children) => <div>{children}</div>}
    >
      {!symbolAfter && symbol}
      {formatNumber(Math.round(children), language)} {symbolAfter && symbol}
    </ConditionalWrapper>
  );
};
