import { Typography, TypographyType } from '@hp/atomic';
import { colors } from '@hp/theme';
import React, { FC } from 'react';

import {
  Container,
  HorizontaLayoutStyled,
  Row,
  Step,
  StepBar,
  StepLink,
  StepText,
} from './styled';
import { ProgressBarProps } from './types';

export const ProgressBar: FC<ProgressBarProps> = ({
  activeStep,
  clickable = true,
  steps,
  children,
}) => {
  const content = [];

  for (let i = 0; i < steps.length; i++) {
    const isComplete = i < activeStep;

    const step =
      isComplete && clickable ? (
        <StepLink to={steps[i].route} key={i}>
          <StepText>
            <Typography
              type={TypographyType.BodySmall}
              color={isComplete ? colors.red_main : colors.gray_light}
            >
              {steps[i] && steps[i].text}
            </Typography>
          </StepText>
          <StepBar complete={isComplete} />
        </StepLink>
      ) : (
        <Step key={i}>
          <StepText>
            <Typography
              type={TypographyType.BodySmall}
              color={isComplete ? colors.red_main : colors.gray_light}
            >
              {steps[i] && steps[i].text}
            </Typography>
          </StepText>
          <StepBar complete={isComplete} />
        </Step>
      );

    content.push(step);
  }

  return (
    <HorizontaLayoutStyled bgColor={colors.transparent}>
      <Container>
        <Row>{content}</Row>
        <Row>{children}</Row>
      </Container>
    </HorizontaLayoutStyled>
  );
};
