import PhoneNumber from 'awesome-phonenumber';
import emojiSupport from 'detect-emoji-support';

import { CountryInfoType, PhoneInfo } from './types';

export const createE164RawValue = (value?: string) => {
  return value?.replace(/\ |\(|\)|\-|\\|\//g, '') ?? '';
};

export const createPhoneObj = (e164RawValue: string, countryCode?: string) => {
  return new PhoneNumber(e164RawValue, countryCode);
};

export const splitPhone = (phone: string) => {
  if (!phone) return ['', ''];
  const obj = createPhoneObj(createE164RawValue(phone));
  return [
    `+${String(obj.getCountryCode())}`,
    String(obj.getNumber('significant')),
  ];
};

export const joinPhone = (phonePrefix: string, phoneMain: string) =>
  phoneMain ? `${phonePrefix}${phoneMain}` : '';

/** input  focus event on iphone , source https://stackoverflow.com/questions/12204571/mobile-safari-javascript-focus-method-on-inputfield-only-works-with-click */
export const safeFocus = (targetInput: HTMLInputElement) => {
  // create invisible dummy input to receive the focus first
  const fakeInput = document.createElement('input');
  fakeInput.setAttribute('type', 'tel');
  fakeInput.style.position = 'absolute';
  fakeInput.style.opacity = '0';
  fakeInput.style.height = '20px';
  fakeInput.style.zIndex = '20';
  fakeInput.style.fontSize = '16px'; // disable auto zoom
  targetInput.parentNode.prepend(fakeInput);
  // focus so that subsequent async focus will work
  fakeInput.focus();
  setTimeout(() => {
    // now we can focus on the target input
    targetInput.focus();
    // cleanup
    fakeInput.remove();
  }, 50);
};

export const getEmoji = (code: string) => {
  return emojiSupport()
    ? code
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397),
        )
    : '';
};

export const format = (phoneInfo: PhoneInfo) => {
  const asYouType = PhoneNumber.getAsYouType(phoneInfo.country.code);
  let raw = createE164RawValue(phoneInfo.phone.toJSON().number.input);
  if (!raw.startsWith('+')) {
    raw = phoneInfo.national
      ? `+${phoneInfo.country.dialCode}${phoneInfo.national}`
      : `+${phoneInfo.country.dialCode}${raw}`;
  }
  (raw + ' ').split('').forEach((x) => asYouType.addChar(x));
  return asYouType.removeChar();
};

export const getDataValue = (phoneInfo: PhoneInfo) =>
  phoneInfo.phone.getNumber?.('e164') || '';

export const createPhoneInfo = (
  input: string,
  defaultCountry: CountryInfoType,
  allCountries: CountryInfoType[],
): PhoneInfo => {
  const rawPhone = createE164RawValue(input);
  if (rawPhone.startsWith('+')) {
    const phone = createPhoneObj(rawPhone);
    const regionCode = (
      phone.getRegionCode() ?? defaultCountry.code
    ).toLowerCase();
    const country =
      allCountries.find((x) => x.code === regionCode) ??
      allCountries.find((x) => x.code === defaultCountry.code);
    const national = getNationalPart(rawPhone, phone);
    return {
      phone,
      country,
      national,
    };
  }

  const phone = createPhoneObj(rawPhone ?? '', defaultCountry.code);
  return {
    phone,
    country: defaultCountry,
    national: getNationalPart(rawPhone, phone),
  };
};

const getNationalPart = (rawPhone: string, phoneNumber: PhoneNumber) => {
  const dialCode = phoneNumber.getCountryCode();
  if (!dialCode) {
    return '';
  }
  const dialCodeLength = String(dialCode).length;
  if (rawPhone.startsWith(String(dialCode))) {
    return rawPhone.substring(dialCodeLength);
  }
  if (rawPhone.startsWith(`+${dialCode}`)) {
    return rawPhone.substring(dialCodeLength + 1);
  }
  return rawPhone;
};

export const formatPhone = (rawPhone: string) => {
  if (!rawPhone) {
    return ' - ';
  }
  const phone = new PhoneNumber(rawPhone);
  return phone.isValid() ? phone.getNumber('international') : rawPhone;
};
