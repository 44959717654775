import { Language } from '@hp/core/shared';
import { breakpoints, colors, elements, spacing, typography } from '@hp/theme';
import styled from 'styled-components';

import { BannerProps } from '../types';

type WrapperProps = BannerProps & { lang: Language };

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${colors.white};
  height: ${({ size }) => (size === 'M' ? '320px' : '172px')};
  max-width: 288px;
  background-color: ${colors.white};
  background-image: url('/images/banners/red_layer_left.png'),
    url('/images/banners/red_layer_left.png'),
    ${({ lang }) =>
      lang === Language.EN
        ? "url('/images/banners/hands_s_en.png')"
        : "url('/images/banners/hands_s.png')"},
    linear-gradient(180deg, #ffffff 0%, #e6e7e8 100%);
  background-position: center left,
    ${({ lang }) =>
      lang === Language.EN ? 'center left 25px' : 'center left 15px'},
    center right;
  background-repeat: no-repeat;
  background-size: contain, contain,
    ${({ lang }) => (lang === Language.EN ? '170px 132px' : '180px 152px')},
    auto;
  margin: ${spacing.m} auto ${spacing.l};

  @media (min-width: ${breakpoints.minDesktop}) {
    justify-content: center;
    height: 136px;
    width: 780px;
    max-width: ${elements.innerElementMaxWidth};
    background-image: url('/images/banners/red_rectangle_desktop_s.png'),
      url('/images/banners/red_layer_desktop_s.png'),
      ${({ lang }) =>
        lang === Language.EN
          ? "url('/images/banners/hands_s_desktop_en.png')"
          : "url('/images/banners/hands_s_desktop.png')"},
      linear-gradient(180deg, #ffffff 0%, #e6e7e8 100%);
    background-position: center left, center left 320px, center right;
    background-size: contain;
  }
`;

export const Heading = styled.h3<BannerProps>`
  text-align: left;
  font-size: 15px;
  line-height: 15px;
  font-weight: ${typography.fontWeight.normal};
  max-width: 130px;
  margin: 40px 0 12px 16px;

  @media (min-width: ${breakpoints.minDesktop}) {
    font-size: 22px;
    line-height: 24px;
    max-width: 380px;
    margin: 0 0 16px 48px;
  }
`;

export const Paragraph = styled.p<BannerProps>`
  text-align: left;
  font-size: 12px;
  line-height: 14px;
  font-weight: ${typography.fontWeight.light};
  max-width: 120px;
  margin: 0 0 auto 16px;

  @media (min-width: ${breakpoints.minDesktop}) {
    font-size: 16px;
    line-height: 19px;
    max-width: 380px;
    margin: 0 0 0 48px;
  }
`;
