import { ButtonType, ChevronIcon, LinkButton, Nowrap } from '@hp/atomic';
import { colors } from '@hp/theme';
import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

import { BannerProps } from '../types';
import { Heading, Paragraph, Wrapper } from './styled';

export const PromoBanner: FC<BannerProps> = ({ placement, size = 'M' }) => {
  return (
    <Wrapper placement={placement} size={size}>
      <Heading placement={placement} size={size}>
        <Trans id="banner.promo.heading">Mějte zásilky pod kontrolou</Trans>
      </Heading>

      <Paragraph placement={placement} size={size}>
        <Trans id="banner.promo.paragraph">
          Založte si účet, zbavte se zbytečného vyplňování{' '}
          <Nowrap>a sledujte</Nowrap> všechny odeslané balíčky.
        </Trans>
      </Paragraph>

      <LinkButton
        to="register"
        type="button"
        buttonType={ButtonType.BANNER_PRIMARY}
        icon={<ChevronIcon color={colors.white} zoom={1.2} />}
        className="gaButton gaButtonAction_promoBanner"
        data-test="thankYou.button.promoBanner"
      >
        <Trans id="banner.promo.button">Chci mít přehled</Trans>
      </LinkButton>
    </Wrapper>
  );
};
