import { breakpoints, colors, elements, spacing } from '@hp/theme';
import styled from 'styled-components';

import { InputProps } from '../atomic/Input';

const handleBorderColor = (error: boolean, touched: boolean) => {
  if (error && touched) {
    return colors.error;
  } else return colors.gray_light;
};

export const FormattedValueWrapper = styled.div<{ secondary?: boolean }>`
  position: absolute;
  background-color: ${({ secondary }) =>
    secondary ? colors.white : colors.input};
  top: 21px;
  right: 30px;
  left: 6px;
  padding-left: 3px;
  pointer-events: none;
`;

type WrapperProps = Pick<
  InputProps,
  'error' | 'touched' | 'withLabel' | 'height'
> & {
  forSelect?: boolean;
  secondary?: boolean;
};

export const InputWrapper = styled.div<WrapperProps>`
  box-sizing: border-box;
  box-shadow: inset 0px 3px 3px rgba(49, 50, 48, 0.05);
  border-radius: 2px;
  background-color: ${({ secondary }) =>
    secondary ? colors.white : colors.input};
  padding: ${({ withLabel }) => (withLabel ? '0px' : spacing.unit(5))}
    ${({ forSelect }) => (forSelect ? spacing.xs : spacing.l)} ${spacing.xxs}
    ${spacing.s};
  border: 1px solid ${({ error, touched }) => handleBorderColor(error, touched)};
  height: ${({ height, withLabel }) =>
    height
      ? height
      : withLabel
      ? elements.inputHeight
      : elements.inputHeightSmall};
  position: relative;
  text-align: left;
`;

export const TextAreaWrapper = styled.div<WrapperProps>`
  box-sizing: border-box;
  box-shadow: inset 0px 3px 3px rgba(49, 50, 48, 0.05);
  border-radius: 2px;
  background-color: ${colors.input};
  padding: ${({ withLabel }) => (withLabel ? spacing.xxs : spacing.unit(5))}
    ${spacing.l} ${spacing.xxs} ${spacing.s};
  border: 1px solid ${({ error, touched }) => handleBorderColor(error, touched)};
`;

export const NumberButtonWrapper = styled.div`
  transition: width 0.3s;
  width: ${elements.buttonHeight};
  :first-child {
    margin-right: ${spacing.s};
  }
  :last-child {
    margin-left: ${spacing.s};
  }
  @media (min-width: ${breakpoints.minDesktop}) {
    width: 30%;
  }
`;

export const NumberFieldWrapper = styled.div`
  display: flex;
  input[type='number'] {
    appearance: textfield;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
  }
`;
