import { breakpoints, colors, elements, spacing, typography } from '@hp/theme';
import styled from 'styled-components';

import { BannerProps } from '../types';

export const Wrapper = styled.div<BannerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${colors.white};
  height: ${({ size }) => (size === 'M' ? '320px' : '172px')};
  max-width: 288px;
  background-color: ${colors.red_main};
  background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.87) 24.96%,
      rgba(255, 255, 255, 0) 69.45%
    ),
    url('/images/banners/promo_m_mobile.png');
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  margin: ${spacing.m} auto ${spacing.l};

  @media (min-width: ${breakpoints.minDesktop}) {
    width: 504px;
    max-width: ${elements.innerElementMaxWidth};
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.68) 17.36%,
        rgba(255, 255, 255, 0) 69.45%
      ),
      url('/images/banners/promo_m_desktop.png');
  }
`;

export const Heading = styled.h3<BannerProps>`
  color: ${colors.red_main};
  text-align: left;
  font-size: 29px;
  line-height: 29px;
  font-weight: ${typography.fontWeight.normal};
  max-width: 224px;
  margin: 24px 0 16px 16px;

  @media (min-width: ${breakpoints.minDesktop}) {
    font-size: 30px;
    line-height: 30px;
    max-width: 224px;
    margin: 38px 0 16px 32px;
  }
`;

export const Paragraph = styled.p<BannerProps>`
  color: ${colors.black};
  text-align: left;
  font-size: 13px;
  line-height: 15.6px;
  font-weight: ${typography.fontWeight.light};
  max-width: 200px;
  margin: 0 0 auto 16px;

  @media (min-width: ${breakpoints.minDesktop}) {
    font-size: 18px;
    line-height: 21.6px;
    max-width: 224px;
    margin: 0 0 auto 32px;
  }
`;
